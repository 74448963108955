<template>
  <v-list-item
    v-bind="$attrs"
    @click="impersonate"
  >
    <v-list-item-action class="mr-4">
      <v-icon small>
        {{ icon }} fa-fw
      </v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>Impersonate {{ title }}</v-list-item-title>
    </v-list-item-content>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </v-list-item>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: 'User',
    },
    icon: {
      type: String,
      default: 'fal fa-user-secret',
    },
  },
  data () {
    return {
      open: false,
      loading: false,
    }
  },
  methods: {
    impersonate () {
      this.$dialog.confirm({
        text: `Log in as ${this.record.first_name} ${this.record.last_name}?`,
        title: 'Impersonate',
      })
      .then((res) => {
        if (res) {
          this.loading = true
          this.$auth.impersonate({
            url: `auth/impersonate/${this.record.uuid}`,
          })
          .finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>
